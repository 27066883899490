<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <div class="text-md-center">
        <h2 class="my-3 headline">Sorry, the link is expired.</h2>
        <div>
          <v-btn color="primary" @click="goHome">Go Home</v-btn>
          <v-btn class="ml-5" color="primary" @click="passwordReset">Send Link Again</v-btn>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ path: "/" });
    },
    passwordReset() {
      this.$router.push({ path: "/forgotPassword" });
    }
  }
};
</script>
<style scoped lang="css">
h1 {
  font-size: 150px;
  line-height: 150px;
  font-weight: 700;
  color: #252932;
  text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
    rgba(61, 61, 61, 0.3) 3px 3px;
}
</style>
